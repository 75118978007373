<template>
  <div class="w-full h-full pt-5 px-3">
    <template>
      <div class="flex flex-row justify-start gap-4">
        <back-button
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="mr-4"
        />
        <div class="flex gap-3 my-2">
          <h1 class="text-xl text-left font-extrabold mt-1">Employees</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="px-3 my-2">
        <div class="flex flex-col w-full h-full">
          <div class="w-full flex py-2 gap-1 border-b-2">
            <p class="text-lg font-bold">{{ tabTitle }}</p>
          </div>
          <employment-details :employment-details="employeeDetails" />
        </div>

        <div class="w-full flex my-2 justify-center">
          <div class="my-3 w-4/5 border-2" />
        </div>

        <div class="w-full my-8 flex gap-3 px-3">
          <div class="w-1/4 flex flex-col p-3">
            <side-bar
              :tabs="sideTabs"
              :current-tab="currentTab"
              @currentTab="currentTab = $event"
            />
          </div>
          <div class="flex flex-col border" style="width: 1px" />

          <div class="w-3/4 flex flex-col mr-1">
            <view-confirmation
              :employment-details="employeeDetails"
              :settings="settingsData"
              v-if="currentTab === 'Assessment'"
            />
            <payrollAssessment
              :employment-details="allEmployeeDetails"
              v-if="currentTab === 'Payroll'"
              @payload="setPayrollPayload($event)"
            />
            <confirm-assessment
              :confirm-payload="payload"
              :employment-details="employeeDetails"
              :slug="actionSlug"
              v-if="currentTab === 'Confirm'"
            />
            <extend-probation
              :employment-details="employeeDetails"
              :settings="settingsData"
              v-if="currentTab === 'Extend Probation'"
              @extendPayload="setExtensionPayload($event)"
            />
          </div>
        </div>

        <div class="flex justify-end gap-4">
          <div
            class="flex mt-2 gap-2 cursor-pointer"
            @click="navigateToPrevTab"
            v-if="sideTabIndex > 1"
          >
            <Icon size="xs" icon-name="icon-arrow-left" />
            <div class="font-10 mr-4">
              <p class="text-romanSilver">
                {{ sideTabIndex - 1 }}/{{ sideTabs.length }}
                Previous
              </p>
              <p class="uppercase font-bold">
                {{ sideTabs[sideTabIndex - 2] }}
              </p>
            </div>
          </div>

          <card
            class="w-10 text-xl mr-2 font-extrabold flex justify-center h-10"
          >
            <p class="text-center my-2">{{ sideTabIndex }}</p>
          </card>

          <div class="flex" v-if="sideTabIndex < sideTabs.length">
            <div class="flex mt-2 cursor-pointer" @click="navigateToNextTab">
              <div class="font-10 mr-4">
                <p class="text-flame">
                  {{ sideTabIndex + 1 }}/{{ sideTabs.length }}
                  Next
                </p>
                <p class="uppercase font-bold">
                  {{ sideTabs[sideTabIndex] }}
                </p>
              </div>
              <Icon size="xs" icon-name="icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import employmentDetails from "@/components/confirmation/employmentDetails";
import Card from "@/components/Card";
import SideBar from "./sideBar";
import viewConfirmation from "./layout/viewConfirmation";
import payrollAssessment from "./layout/payrollAssessment";
import confirmAssessment from "./layout/confirmAssessment";
import extendProbation from "./layout/extendProbation";

export default {
  name: "ViewAssesment",
  components: {
    BackButton,
    Breadcrumb,
    employmentDetails,
    SideBar,
    Card,
    viewConfirmation,
    payrollAssessment,
    confirmAssessment,
    extendProbation,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Employees",
          href: "Employees",
          id: "Employees",
        },
        {
          disabled: false,
          text: "Employee Directory",
          href: "EmployeeDirectory",
          id: "Employee Directory",
        },
        {
          disabled: false,
          text: "Confirmation Checklist",
          id: "Confirmation Checklist",
        },
      ],
      employeeDetails: {},
      settingsData: {},
      currentTab: this.$route.query.tab,
      loading: true,
      sideTabs: [],
      sideTabIndex: null,
      allEmployeeDetails: {},
      payrollPayload: {},
      payload: {},
      actionSlug: "",
      tabTitle: "",
    };
  },

  methods: {
    navigateToNextTab() {
      const nextTabIndex = this.sideTabIndex + 1;
      if (nextTabIndex <= this.sideTabs.length) {
        this.currentTab = this.sideTabs[nextTabIndex - 1];
        this.sideTabIndex = nextTabIndex;
      }
    },
    navigateToPrevTab() {
      const prevTabIndex = this.sideTabIndex - 1;
      if (prevTabIndex < this.sideTabs.length) {
        this.currentTab = this.sideTabs[prevTabIndex - 1];
        this.sideTabIndex = prevTabIndex;
      }
    },
    formatDate(value) {
      const dateString = value;
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    },
    setPayrollPayload(data) {
      this.payload = { ...this.payrollPayload, ...data };
      this.actionSlug = "confirm";
    },
    setExtensionPayload(data) {
      this.payload = { ...data };
      this.actionSlug = "extension";
    },
  },

  created() {
    if (this.$route.query.tab === "Assessment") {
      this.sideTabs.push("Assessment");
      this.tabTitle = "Confirmation Assessment";
    } else if (this.$route.query.tab === "Extend Probation") {
      this.tabTitle = "Extend Probation";
      this.sideTabs.push("Assessment", "Extend Probation", "Confirm");
    } else {
      this.tabTitle = "Confirmation";
      this.sideTabs.push("Assessment", "Payroll", "Confirm");
    }
  },

  mounted() {
    if (this.$route.query.slug === "admin") {
      this.$_employeeOnProbation("").then((response) => {
        const filteredEmployee = response.data.data.employees.filter(
          (x) => x.id === this.$route.params.id
        );
        this.employeeDetails = Object.assign({}, ...filteredEmployee);
        this.loading = false;
        this.payrollPayload = {
          confirmUserId: this.employeeDetails.userId,
          orgId: this.$orgId,
          assesementSummary:
            this.employeeDetails.employeeConfirmation?.assesementSummary ||
            null,
          recommendation:
            this.employeeDetails.employeeConfirmation?.recommendation || null,
          effectiveDate: this.employeeDetails.employeeConfirmation
            ? this.formatDate(
                this.employeeDetails.employeeConfirmation.effectiveDate
              )
            : null,
          confirmationDueDate:
            this.employeeDetails?.confirmationDueDate || null,
          performanceRatingScore:
            this.employeeDetails.employeeConfirmation?.performanceRatingScore ||
            null,
          performanceRating:
            this.employeeDetails.employeeConfirmation?.performanceRating ||
            null,
        };
        this.payload = this.payrollPayload;
      });
    } else {
      this.$_employeesConfirmation("").then((response) => {
        const filteredEmployee = response.data.data.employees.filter(
          (x) => x.id === this.$route.params.id
        );
        this.employeeDetails = Object.assign({}, ...filteredEmployee);
        this.loading = false;
      });
    }

    this.$_getOneEmployeeAll({
      id: this.$route.params.id,
    }).then((response) => {
      this.allEmployeeDetails = response.data;
    });

    this.$_getConfirmationSettings()
      .then((response) => {
        const {
          confirmationMandatory,
          criteria,
          defaultExtendPeriod,
          probationPeriod,
          ratings,
          exception,
        } = response.data.confirmationProbationRating;
        this.settingsData = {
          confirmationMandatory,
          criteria,
          defaultExtendPeriod,
          probationPeriod,
          ratings,
          exception,
        };
      })
      .catch((err) => {
        throw new Error(err);
      });

    this.currentTab = this.$route.query.tab;
    this.sideTabIndex = this.sideTabs.indexOf(this.$route.query.tab) + 1;
  },
};
</script>

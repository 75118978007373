<template>
  <div class="w-full flex flex-col gap-4 ml-3">
    <div class="w-full flex gap-3">
      <div class="w-1/2 flex flex-col gap-2">
        <div class="flex justify-between w-full items-center">
          <p class="text-sm mt-4 mx-1">Start Period</p>
          <h6
            class="text-xs mt-4 mr-3 cursor-pointer text-mediumSeaGreen"
            @click="editStartingFrom = true"
            v-if="!editStartingFrom"
          >
            Edit
          </h6>
        </div>
        <div class="border border-romanSilver rounded-md">
          <datepicker
            placeholder="--Start Date--"
            :input-class="
              !editStartingFrom
                ? 'date-input date-input_disabled cursor-not-allowed '
                : 'date-input'
            "
            :disabled="!editStartingFrom"
            style="width: 100%"
            v-model="startDate"
          />
        </div>
      </div>

      <div class="w-1/2 flex flex-col gap-2">
        <div class="flex justify-between w-full items-center">
          <p class="text-sm mt-4 mx-1">End Period</p>
        </div>
        <datepicker
          placeholder="--End Date--"
          :input-class="
            !editEnding
              ? 'date-input date-input_disabled cursor-not-allowed '
              : 'date-input'
          "
          :disabled="!editEnding"
          style="width: 100%"
          v-model="endDate"
        />
      </div>
    </div>

    <div class="w-full flex flex-col">
      <h5 class="mt-3 text-sm">Additional Comments</h5>
      <span>
        <CTextarea
          placeholder="--Start typing--"
          class="mt-2"
          value=""
          :row="20"
          :height="104"
          :col="30"
          v-model="additionalComments"
        />
      </span>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";

export default {
  name: "ExtendProbation",
  components: { Datepicker, CTextarea },
  props: {
    employmentDetails: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      startDate: null,
      editStartingFrom: false,
      editEnding: false,
      endDate: null,
      additionalComments: "",
    };
  },
  watch: {
    startDate(date) {
      const payload = {
        confirmUserId: this.employmentDetails.userId,
        orgId: this.$orgId,
        extendBy: this.settings.defaultExtendPeriod,
        recommendation: this.employmentDetails.employeeConfirmation
          ? this.employmentDetails.employeeConfirmation.recommendation
          : null,
        additionalComments: this.additionalComments,
        effectiveDate: this.$DATEFORMAT(
          new Date(date || new Date()),
          "yyyy/MM/dd"
        ),
      };
      if (this.employmentDetails.employeeConfirmation) {
        payload.id = this.employmentDetails.employeeConfirmation.id;
      }

      this.endDate = new Date(date);
      this.endDate.setMonth(this.endDate.getMonth() + this.settings.defaultExtendPeriod);
      this.$emit("extendPayload", payload);
    },
  },
  mounted() {
    this.startDate = this.employmentDetails.employeeConfirmation.confirmationDueDate;
  }
};
</script>
